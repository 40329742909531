import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(app)/amenities": [6,[2]],
		"/(app)/amenities/[slug]": [7,[2]],
		"/(app)/categories/amenities/[slug]": [8,[2]],
		"/(app)/categories/locations/[slug]": [9,[2]],
		"/(app)/categories/sub-categories/[slug]": [10,[2]],
		"/(error)/errors": [17,[3]],
		"/(app)/events": [11,[2]],
		"/(app)/events/[slug]": [12,[2]],
		"/(loading)/loading": [18,[4]],
		"/(app)/locations": [13,[2]],
		"/(app)/locations/[slug]": [14,[2]],
		"/(app)/locations/[slug]/routing": [15,[2]],
		"/(app)/search/results": [16,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';