import * as Sentry from '@sentry/svelte';
import type { HandleClientError } from '@sveltejs/kit';

Sentry.init({
  dsn: 'https://e1da25c8306c4df2a6a3f9ae631d8fc1@o133726.ingest.sentry.io/4504005896306688',
  integrations: [],
  sampleRate: 1, // we have very few sessions, keeping high for now
  enableTracing: false,
});

export const handleError: HandleClientError = async ({ error, event }) => {
  const errorId = crypto.randomUUID();
  Sentry.captureException(error, { extra: { event, errorId } });

  return {
    message: 'Something went wrong.',
    errorId,
  };
};
